import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		'&::before': {
			display: "block",
			content: " ",
			marginTop: -285,
			height: "285px",
			visibility: "hidden",
			"pointer-events": "none",
		}
	},

	decoration: {
		position: 'absolute',
		right: 0,
		top: 288,
		zIndex: 2,
		[theme.breakpoints.down(1440)]: {
			display: "none",

		}
	}
}))