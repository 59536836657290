import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: '0 68px',
		paddingTop: 64,
		paddingRight: 68,
		marginBottom: 50,
		//MOBILE
		[theme.breakpoints.down('xs')]: {
			padding: "56px 16px",
			flexDirection: 'column-reverse',
			marginBottom: 119,
			paddingBottom: 0,

		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			padding: "64px 32px",
			flexDirection: 'column-reverse',
			marginBottom: 64,
			paddingBottom: 0,

		},
		[theme.breakpoints.between(1024, 1440)]: {
			padding: '0 24px',
			paddingTop: 90,
			flexDirection: 'unset',

		},
		// [theme.breakpoints.up(1845)]: {
		// 	paddingTop: 127,
		// 	padding: '0 18%',


		// }
	},
	infoContainer: {
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: "100%",
		},
		[theme.breakpoints.between(1024, 1440)]: {
			width: "60%",
		}
	},
	imageContainer: {
		width: '50%',
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			justifyContent: 'center',
			marginBottom: 24,
		},
		[theme.breakpoints.between(1024, 1440)]: {
			width: "40%",
		}
	},

	title: {
		fontSize: 50,
		lineHeight: "68px",
		fontWeight: 'bold',
		marginBottom: 16,
		//MOBILE
		[theme.breakpoints.down('xs')]: {
			fontSize: 32,
			lineHeight: "40px",
		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			fontSize: 40,
			lineHeight: "56px",

		},
	},
	description: {
		fontSize: 24,
		lineHeight: "44px",
		marginBottom: 76,
		maxWidth: "90%",
		//MOBILE
		[theme.breakpoints.down('xs')]: {
			fontSize: 18,
			lineHeight: "32px",
			letterSpacing: '-0.011em',
			marginBottom: 40,

		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			marginBottom: 32,
			letterSpacing: '-0.011em'

		},

	},
	buttonsContainer: {
		gap: 20,
		[theme.breakpoints.down(380)]: {
			gap: 5,
		}

	},
	button: {
		[theme.breakpoints.down(380)]: {
			width: '49%',
			padding: '15px 23px'
		},
		padding: '14px 40px'
	},

	figure: {
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			display: 'flex',
			justifyContent: 'center'

		}
	},
	image: {
		width: 560,
		height: 414,
		borderRadius: 8,
		//MOBILE
		[theme.breakpoints.down('xs')]: {
			width: "100%",
			height: "100%",
		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			width: "100%",
			height: "100%",
		},
		[theme.breakpoints.between(1024, 1440)]: {
			width: "100%",
			height: "80%",
			//marginTop: 20
		},
	},

	starCredit: {
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		bottom: 34,
		left: -25,
		//MOBILE
		[theme.breakpoints.down('xs')]: {
			bottom: 6,
			left: 0,
		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			bottom: 33,
			left: 0,
		},
		[theme.breakpoints.between(1024, 1440)]: {
			bottom: 100,
			left: -25,
		},
	},

	hide: {
		display: 'none'
	},

	starContainer: {
		background: 'white',
		width: 51,
		height: 51,
		boxShadow: "0px 5px 22px rgba(57, 74, 97, 0.12);",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: "50%",
		zIndex: 3
	},
	star: {
		width: 29,
		height: 27
	},

	textContainer: {
		width: 314,
		paddingLeft: 30,
		background: 'white',
		marginLeft: -25,
		zIndex: 2,
		height: 38,
		display: 'flex',
		alignItems: 'center',

	},
	text: {
		fontSize: 14,
		lineHeight: "54px",
		fontWeight: 'bold',
		//MOBILE
		[theme.breakpoints.down('xs')]: {
			fontSize: 12,
		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			fontSize: 12,
		},
	},
	triangleContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: -1

	},
	triangle1: {
		width: 17.3,
		height: 19.3,
		transform: 'rotate(90deg)',


	},
	triangle2: {
		width: 19.3,
		height: 19.3,
		transform: 'rotate(360deg)'
	},

}))