import { Card, Grid, Typography } from "@material-ui/core";
import { motion } from "framer-motion";
import React from "react";
import { useStyles } from "./CreditDetails.styles";

interface CreditDetailsProps {
	credit: {
		maxCapacity: string;
		minCredit: string;
		interestFrom: string;
		interestTo: string;
	}
}

export function CreditDetails({ credit }: CreditDetailsProps) {
	const classes = useStyles()
	return (
		<Grid container justifyContent="space-between" className={classes.root}>
			<Grid
				item
				className={classes.cardRow}
				// data-sal="slide-down"
				// data-sal-delay="0"
				// data-sal-duration="1000"
				// data-sal-easing="ease"
				initial={{ opacity: 0, y: -100 }}
				transition={{ duration: 1.1 }}
				whileInView={{ opacity: 1, y: 0 }}
				viewport={{ once: true }}
				component={motion.div}
			>
				<Card elevation={0} className={classes.card}>
					<Grid container direction="column" className={classes.cardContainer}>
						<Grid item>
							<Typography className={classes.title}>
								Características
							</Typography>
						</Grid>
						<Grid item >
							<ul className={classes.list}>
								<li>
									<Typography>
										<strong>Aforo máximo:</strong> {credit.maxCapacity}
									</Typography>
								</li>
								<li>
									<Typography>
										<strong>Monto mínimo de crédito:</strong> {credit.minCredit}
									</Typography>
								</li>
								<li>
									<Typography>
										<strong>Tasa </strong> del {credit.interestFrom} al {credit.interestTo}
									</Typography>
								</li>
								<li>
									<Typography>
										Trámite en línea
									</Typography>
								</li>
								<li>
									<Typography>
										Asesoría hipotecaria incluida
									</Typography>
								</li>

							</ul>
						</Grid>
					</Grid>
				</Card>
			</Grid>

			<Grid
				item
				className={classes.cardRow}
				// data-sal="slide-down"
				// data-sal-delay="0"
				// data-sal-duration="1000"
				// data-sal-easing="ease"
				initial={{ opacity: 0, y: -100 }}
				transition={{ duration: 1.1 }}
				whileInView={{ opacity: 1, y: 0 }}
				viewport={{ once: true }}
				component={motion.div}
			>
				<Card elevation={0} className={classes.card}>
					<Grid container direction="column" className={classes.cardContainer}>
						<Grid item>
							<Typography className={classes.title}>
								¿Qué necesitas?
							</Typography>
						</Grid>
						<Grid item>
							<ul className={classes.listRequiremts}>
								<li>
									<Typography>
										Documentos relacionados a tu identificación, ingresos y al inmueble.
									</Typography>
								</li>
								<li>
									<Typography>
										Ser mayor a 23 años y menor a 60.
									</Typography>
								</li>
								<li>
									<Typography>
										Mínimo de ingresos: $18,000 pesos.
									</Typography>
								</li>
								<li>
									<Typography>
										Buen comportamiento crediticio en los últimos 12 meses.
									</Typography>
								</li>
							</ul>
						</Grid>
					</Grid>
				</Card>
			</Grid>

		</Grid>
	)
}