import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./MortgageImprovement.styles";
import { CreditHeader } from "../CreditHeader";
import { CreditDetails } from "../CreditDetails";

export function MortgageImprovement() {
	const classes = useStyles()
	return (
		<Grid container className={classes.root} id="mejora-de-hipoteca">
			<img
				className={classes.decoration1}
				src="/assets/images/creditos_decoration_2.svg"
				alt=""
			/>
			<img
				className={classes.decoration2}
				src="/assets/images/creditos_decoration_3.svg"
				alt=""
			/>
			<CreditHeader
				idSection=""
				title="Mejora tu hipoteca actual"
				description="¿Quieres mejorar el crédito que ya tienes?, nosotros te damos algo mucho mejor. Solo dinos cuáles son las características y condiciones de tu crédito actual y te hacemos una mejor oferta."
				imageURL="/assets/images/foto-credito-mejora.jpg"
			/>
			<CreditDetails
				credit={{
					maxCapacity: "90%",
					minCredit: "$600,000",
					interestFrom: "9.50%",
					interestTo: "11.30%"
				}}
			/>
		</Grid>
	)
}