import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		gap: 40,
		padding: "0 45px",
		zIndex: 3,
		position: 'relative',
		paddingRight: 68,
		paddingBottom: 68,
		[theme.breakpoints.up(1441)]: {
			gap: 0,
		},
		//MOBILE
		[theme.breakpoints.down('xs')]: {
			padding: "0 16px",
		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			padding: "0 32px",
		},
		[theme.breakpoints.between(1024, 1440)]: {
			//justifyContent: 'center',
			padding: "0 24px",

		}

	},
	cardRow: {
		[theme.breakpoints.between('sm', 'sm')]: {
			width: "100%",

		},
		[theme.breakpoints.between(1024, 1440)]: {
			width: "100%",



		},
		[theme.breakpoints.up(1440)]: {
			width: "48%",

		}
	},
	card: {
		boxShadow: "0px 5px 22px rgba(57, 74, 97, 0.12)",
		borderRadius: 8,
		width: 632,
		height: 372,
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			height: "100%",

		},
		[theme.breakpoints.between(1024, 1440)]: {
			width: "100%",
		},
		[theme.breakpoints.up(1440)]: {
			width: "100%",

		}
	},
	cardContainer: {
		padding: '40px 40px',
		[theme.breakpoints.down('sm')]: {
			padding: "24px",
		}
	},
	title: {
		fontSize: 34,
		lineHeight: '54px',
		fontWeight: 900,
		[theme.breakpoints.down('sm')]: {
			fontSize: 32,

		}
	},
	list: {
		paddingInlineStart: '0px',
		[theme.breakpoints.down('sm')]: {
			paddingInlineStart: 20,

		},
		"& li": {
			color: theme.palette.primary.main,
			fontSize: 24
		},
		"& p": {
			fontSize: 24,
			lineHeight: '44px',
			[theme.breakpoints.down('sm')]: {
				fontSize: 20,

			}
		}
	},
	listRequiremts: {
		paddingInlineStart: '0px',
		[theme.breakpoints.down('sm')]: {
			paddingInlineStart: 20,

		},
		"& li": {
			color: theme.palette.primary.main,
			fontSize: 24
		},
		"& p": {
			fontSize: 24,
			lineHeight: '38px',
			[theme.breakpoints.down('sm')]: {
				fontSize: 20,
				lineHeight: '28px',


			}
		}
	},
}))