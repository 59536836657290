import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./HousePurchase.styles";
import { CreditHeader } from "../CreditHeader";
import { CreditDetails } from "../CreditDetails";

export function HousePurcharse() {
	const classes = useStyles()
	return (
		<Grid container className={classes.root}>
			<span id="compra-una-casa"> &nbsp; </span>
			<CreditHeader
				idSection="compra-una-casa"
				title="Crédito para comprar casa o departamento"
				description="Si estás buscando comprar una casa o un departamento pero tienes poco tiempo para hacer trámites cansados y papeleo esta opción es para ti."
				imageURL="/assets/images/foto-creditos-casa.jpg"
				creditStar
			/>
			<CreditDetails
				credit={{
					maxCapacity: "90%",
					minCredit: "$600,000",
					interestFrom: "9.9%",
					interestTo: "12.9%"
				}}
			/>
			<img
				alt=""
				className={classes.decoration}
				src="/assets/images/creditos_decoration_1.svg"
			/>
		</Grid>
	)
}