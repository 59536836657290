import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root:{
		position: 'relative',
		paddingBottom: 100
	},
	decoration2:{
		position: 'absolute',
		left: 0,
		bottom: "-4.5%",
		zIndex: -1,
	},
	decoration1: {
		position: 'absolute',
		top: 350,
		left: "50%",
		right:0,
		zIndex: 2,
		[theme.breakpoints.up(1441)]:{
			left: "56%",

		},
		[theme.breakpoints.down(1440)]: {
			display:"none",

		}
	} 
}))