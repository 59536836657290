import { Grid, Typography } from "@material-ui/core";
import { Star } from "@material-ui/icons";
import clsx from "clsx";
import { motion } from "framer-motion";
import React from "react";
import { Button } from "../..";
import { useStyles } from "./CreditHeader.styles";

interface CreditHeaderProps {
	title: string;
	description: string;
	imageURL: string;
	creditStar?: boolean;
	idSection: string;
}

export function CreditHeader({ title, description, imageURL, creditStar, idSection }: CreditHeaderProps) {
	const classes = useStyles()
	return (
		<Grid container className={classes.root}>
			<Grid
				item
				className={classes.infoContainer}
				// data-sal="slide-right"
				// data-sal-delay="0"
				// data-sal-duration="1000"
				// data-sal-easing="ease"

				initial={{ opacity: 0, y: 100 }}
				transition={{ duration: 1.1, delay: .2 }}
				whileInView={{ opacity: 1, y: 0 }}
				viewport={{ once: true }}
				component={motion.div}
			>
				<Grid item>
					<Typography color="primary" className={classes.title}>
						{title}
					</Typography>
					<Typography className={classes.description}>
						{description}
					</Typography>
				</Grid>
				<Grid item container className={classes.buttonsContainer}>
					<Button
						className={classes.button}
						variant="contained"
						color="primary"
						target="_self"
						href={`${process.env.GATSBY_KREDI_APP_BASE_URL}/`}
						useNativeAnchor
					>
						Solicitar ahora
					</Button>
				</Grid>
			</Grid>
			<Grid item className={classes.imageContainer}>
				<div className={classes.figure}>
					<motion.img
						className={classes.image}
						src={imageURL}
						alt={title}
						initial={{ opacity: 0, y: 100 }}
						transition={{ duration: 1.1 }}
						whileInView={{ opacity: 1, y: 0 }}
						viewport={{ once: true }}
					//component={motion.div}
					// data-sal="slide-down"
					// data-sal-delay="0"
					// data-sal-duration="1000"
					// data-sal-easing="ease"
					/>
					<motion.div
						initial={{ opacity: 0, y: 100 }}
						transition={{ duration: 1.1, }}
						whileInView={{ opacity: 1, y: 0 }}
						viewport={{ once: true }}
						className={clsx(classes.starCredit, { [classes.hide]: !creditStar })}
					>
						<div className={classes.starContainer}>
							<motion.img
								className={classes.star}
								src="/assets/images/star.svg"
								alt=""
							// data-sal="flip-up"
							// data-sal-delay="0"
							// data-sal-duration="1000"
							// data-sal-easing="ease"
							// initial={{ opacity: 0, y: 100 }}
							// transition={{ duration: 1.1, }}
							// whileInView={{ opacity: 1, y: 0 }}
							// viewport={{ once: true }}
							/>
						</div>
						<div className={classes.textContainer}>
							<Typography
								className={classes.text}
							// data-sal="zoom-in"
							// data-sal-delay="0"
							// data-sal-duration="1000"
							// data-sal-easing="ease"
							// initial={{ opacity: 0, y: 100 }}
							// transition={{ duration: 1.1, }}
							// whileInView={{ opacity: 1, y: 0 }}
							// viewport={{ once: true }}
							// component={motion.p}
							>
								El más solicitado por nuestros clientes
							</Typography>
						</div>
						<div className={classes.triangleContainer} >
							<svg className={classes.triangle1}>
								<polygon points="0,0 0,100 100,100" fill="white" />
							</svg>
							<svg className={classes.triangle2}>
								<polygon points="0,0 0,100 100,100" fill="white" />
							</svg>
						</div>

					</motion.div>

				</div>
			</Grid>
		</Grid >
	)
}