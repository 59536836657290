import React from "react";
import { FAQ, Footer } from "../components";
import { HousePurcharse, MortgageImprovement } from '../components/Credits'
import { Layout } from "../layout";

function Creditos({ location }: any) {
	return (
		<Layout title="Kredi | Creditos" location={location}>
			<HousePurcharse />
			<MortgageImprovement />
			<FAQ />
			<Footer title="Un crédito hipotecario cómo nunca antes se había visto" />
		</Layout>
	)
}

export default Creditos